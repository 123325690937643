<template>
  <Container v-if="lotInsideDetail" :style="{ padding: 0 }">
    <div
      class="h-15 px-6 bg-black flex justify-between text-white items-center"
    >
      <div class="text-xl flex items-center font-bold">{{ lotInsideDetail.title }}</div>
      <div class="text-sm">Created : {{ formatDate(lotInsideDetail.createdAt || '-') }} | Updated : {{ formatDate(lotInsideDetail.createdAt || '-') }}</div>
    </div>
    <div class="pt-10 pb-10 px-10 mb-10 relative">
      <div class="mb-8 border-b pb-8">
        <strong class="text-bold mb-4 block">thumbnail</strong>
        <div class="relative" style="width: 427px; height: 215px;">
          <div class="file-image"
               :style="{
                backgroundImage: `url(${inside.thumbnailImage})`,
              }"
          ></div>
        </div>
      </div>
      <strong class="text-bold mb-4 block">내용</strong>
      <div v-html="inside.contents"></div>
      <div class="flex items-center justify-center">
        <button
            @click="handleRemove(lotInsideDetail.lotInsideId)"
            class="
                        w-60
                        h-16
                        border border-black
                        shadow
                        flex
                        items-center
                        justify-center
                        mt-10
                        mr-2
                        admin-button
                        bg-gray-400
                        text-white
                      "
        >
          <IconBase color="#fff">
            <RemoveIcon></RemoveIcon>
          </IconBase>
          <span class="ml-2">삭제</span>
        </button>
        <button
            @click="gotoEdit"
            class="
                        w-60
                        h-16
                        border border-black
                        shadow
                        flex
                        items-center
                        justify-center
                        mt-10
                        admin-button
                      "
        >
          <IconBase>
            <EditIcon></EditIcon>
          </IconBase>
          <span class="ml-2">수정</span>
        </button>
      </div>
    </div>
  </Container>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import convertNumWithComma from '@/utils/convertNumWithComma';
import Container from '@/components/Container.vue';
import router from '@/router/index';
import scrollToTopOnMountMixin from '@/mixins/scrollToTopOnMountMixin';
import partnerAPI from '@/service/partnerAPI';
import format from 'date-fns/format';
import { useRoute } from 'vue-router';
import IconBase from '@/components/icons/IconBase.vue';
import RemoveIcon from '@/components/icons/RemoveIcon.vue';
import EditIcon from '@/components/icons/EditIcon.vue';
import getServerErrorMessage from '@/utils/getServerErrorMessage';

export default defineComponent({
  name: 'LotInsideDetail',
  components: {
    Container,
    IconBase,
    RemoveIcon,
    EditIcon,
  },
  mixins: [scrollToTopOnMountMixin],
  props: {},
  setup() {
    const lotInsideDetail = ref<any>(null);
    const lotInsideIdParam = ref<number>();
    const route = useRoute();

    onMounted(async () => {
      lotInsideIdParam.value = route.params.lotInsideId
        ? parseInt(route.params.lotInsideId as string)
        : undefined;

      console.log(route.params.lotInsideId)
      // lotInsideDetail
      if (!lotInsideIdParam.value) {
      } else {
        // 저장된 상품정보를 가져온다.
        await fetchLotInside(lotInsideIdParam.value);
      }
    });

    const inside = computed({
      get: () => {
        const data = lotInsideDetail.value
        return {
          ...data,
          contents: data.contents
        }
      }, set: () => {
        console.log('set')
      }
    })
    const fetchLotInside = async (lotInsideId) => {
      try {
        const { data } = await partnerAPI.adminLotInside.adminLotInside({
          lotInsideId,
        });
        lotInsideDetail.value = (data as any).data;

      } catch (e) {
        router.go(-1);
        alert(getServerErrorMessage(e));
      }
    };

    const handleRemove = async (lotInsideId) => {
      if (confirm('삭제하시겠습니까?')) {
        try {
          const { data } = await partnerAPI.adminLotInside.adminLotInsideDelete({
            lotInsideId,
          });
          alert(data.message)
          router.push({
            path: '/app/lotInside/list'
          })
        } catch (error) {
          console.error(error);
          alert(error)
        }
      }
    }

    const formatDate = (date: string) => {
      return formatDatePattern(date, 'yyyy/MM/dd HH:mm');
    };

    const formatDatePattern = (date: string, pattern) => {
      return format(new Date(date), pattern);
    };

    const gotoEdit = () => {
      router.push({
        path: `/app/lotInside/register/${lotInsideIdParam.value}`
      })
    }

    return {
      lotInsideIdParam,
      lotInsideDetail,
      inside,
      gotoEdit,
      handleRemove,
      fetchLotInside,
      formatDate,
      formatDatePattern,
      convertNumWithComma,
    };
  },
});
</script>

<style lang="scss" scoped>
.ProductInfoTable__row {
  border-bottom: 1px solid #ddd;
  padding: 0.65rem 0;
  &:last-child {
    border-bottom: none;
  }
  &.with-border {
    border-bottom: 1px solid #ddd;
  }
  &.with-button {
    padding: 5px 0;
  }
}

.InfoTable__labelColumn {
  color: #999;
  width: 144px;
  flex-shrink: 0;
}
.member-list__link {
  position: absolute;
  bottom: -60px;
  left: 0;
}

.file-image {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}
@import "../../../assets/style/label";
</style>
