
import { computed, defineComponent, onMounted, ref } from 'vue';
import convertNumWithComma from '@/utils/convertNumWithComma';
import Container from '@/components/Container.vue';
import router from '@/router/index';
import scrollToTopOnMountMixin from '@/mixins/scrollToTopOnMountMixin';
import partnerAPI from '@/service/partnerAPI';
import format from 'date-fns/format';
import { useRoute } from 'vue-router';
import IconBase from '@/components/icons/IconBase.vue';
import RemoveIcon from '@/components/icons/RemoveIcon.vue';
import EditIcon from '@/components/icons/EditIcon.vue';
import getServerErrorMessage from '@/utils/getServerErrorMessage';

export default defineComponent({
  name: 'LotInsideDetail',
  components: {
    Container,
    IconBase,
    RemoveIcon,
    EditIcon,
  },
  mixins: [scrollToTopOnMountMixin],
  props: {},
  setup() {
    const lotInsideDetail = ref<any>(null);
    const lotInsideIdParam = ref<number>();
    const route = useRoute();

    onMounted(async () => {
      lotInsideIdParam.value = route.params.lotInsideId
        ? parseInt(route.params.lotInsideId as string)
        : undefined;

      console.log(route.params.lotInsideId)
      // lotInsideDetail
      if (!lotInsideIdParam.value) {
      } else {
        // 저장된 상품정보를 가져온다.
        await fetchLotInside(lotInsideIdParam.value);
      }
    });

    const inside = computed({
      get: () => {
        const data = lotInsideDetail.value
        return {
          ...data,
          contents: data.contents
        }
      }, set: () => {
        console.log('set')
      }
    })
    const fetchLotInside = async (lotInsideId) => {
      try {
        const { data } = await partnerAPI.adminLotInside.adminLotInside({
          lotInsideId,
        });
        lotInsideDetail.value = (data as any).data;

      } catch (e) {
        router.go(-1);
        alert(getServerErrorMessage(e));
      }
    };

    const handleRemove = async (lotInsideId) => {
      if (confirm('삭제하시겠습니까?')) {
        try {
          const { data } = await partnerAPI.adminLotInside.adminLotInsideDelete({
            lotInsideId,
          });
          alert(data.message)
          router.push({
            path: '/app/lotInside/list'
          })
        } catch (error) {
          console.error(error);
          alert(error)
        }
      }
    }

    const formatDate = (date: string) => {
      return formatDatePattern(date, 'yyyy/MM/dd HH:mm');
    };

    const formatDatePattern = (date: string, pattern) => {
      return format(new Date(date), pattern);
    };

    const gotoEdit = () => {
      router.push({
        path: `/app/lotInside/register/${lotInsideIdParam.value}`
      })
    }

    return {
      lotInsideIdParam,
      lotInsideDetail,
      inside,
      gotoEdit,
      handleRemove,
      fetchLotInside,
      formatDate,
      formatDatePattern,
      convertNumWithComma,
    };
  },
});
